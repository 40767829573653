<template>
  <div>
    <v-img
        :src="headerImage"
      >
        <v-layout
          fill-height
          align-end
          class="pt-5"
        >
          <v-flex
            xs12
          >
          <div class="frontblue pa-1"></div>
          </v-flex>
        </v-layout>
    </v-img>
    <v-container fluid pa-0 ma-0>
      <v-layout justify-center row wrap pa-4>
        <v-flex xs12 md3 my-2 mb-5>
        </v-flex>
        <v-flex xs12 md6 minus-top-margin z-index-1 white>
          <div class="frontblue pa-1"/>
            <div>
              <div
                :class="{'pa-3': $vuetify.breakpoint.smAndDown, 'pa-5': $vuetify.breakpoint.mdAndUp}"
              >
                <h1 class="headline text-header-color mb-4">
                  Privacy Policy
                </h1>
                <div class="py-4">
                  Tandprothetische Praktijk Plasmans hecht veel waarde aan de bescherming van uw persoonsgegevens. In deze Privacy policy willen we heldere en transparante informatie geven over hoe wij omgaan met persoonsgegevens.
                </div>
                <div class="pb-4">
                  Wij doen er alles aan om uw privacy te waarborgen en gaan daarom zorgvuldig om met persoonsgegevens. Tandprothetische Praktijk Plasmans houdt zich in alle gevallen aan de toepasselijke wet- en regelgeving, waaronder de Algemene Verordening Gegevensbescherming. Dit brengt met zich mee dat wij in ieder geval:
                </div>
                <div class="pb-4">
                  <ul>
                    <li>Uw persoonsgegevens verwerken in overeenstemming met het doel waarvoor deze zijn verstrekt, deze doelen en type persoonsgegevens zijn beschreven in deze Privacy policy;</li>
                    <li>Verwerking van uw persoonsgegevens beperkt is tot enkel die gegevens welke minimaal nodig zijn voor de doeleinden waarvoor ze worden verwerkt;</li>
                    <li>
                      Vragen om uw uitdrukkelijke toestemming als wij deze nodig hebben voor de verwerking van uw persoonsgegevens;
                    </li>
                    <li>
                      Passende technische en organisatorische maatregelen hebben genomen zodat de beveiliging van uw persoonsgegevens gewaarborgd is;
                    </li>
                    <li>
                      Geen persoonsgegevens doorgeven aan andere partijen, tenzij dit nodig is voor uitvoering van de doeleinden waarvoor ze zijn verstrekt;
                    </li>
                    <li>
                      Op de hoogte zijn van uw rechten omtrent uw persoonsgegevens, u hierop willen wijzen en deze respecteren.
                    </li>
                  </ul>
                </div>
                <div class="pb-4">
                  Als Tandprothetische Praktijk Plasmans zijn wij verantwoordelijk voor de verwerking van uw persoonsgegevens. Indien u na het doornemen van ons Privacy policy, of in algemenere zin, vragen heeft hierover of contact met ons wenst op te nemen kan dit via de contactgegevens onder aan dit document.
                </div>
              </div>
            </div>
          <div class="frontblue pa-1"/>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>

export default{
  computed: {

    headerImage () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return require('../../assets/privacy.png')
        default : return require('../../assets/privacy.png')
      }
    }
  }
}
</script>