<template>
  <div>
    <div>
      <div v-if="selectedProfile">
        <v-layout 
          align-center 
          row 
          :class="{'pa-2': $vuetify.breakpoint.smAndDown, 'pa-4': $vuetify.breakpoint.mdAndUp}"
        >
          <v-flex class="text-sm-center pointer">
            <v-avatar
              size="100"
              v-for="item in team"
              :key="item.id"
              class="ma-2"
              :class="{
                'hovergrey': selectedProfile.id !== item.id
              }"
              @click="switchTab(item)"
            >
              <img :src="item.foto"
                alt="avatar"
              />
            </v-avatar>
          </v-flex>
        </v-layout>
        <div 
          :class="{'pa-3': $vuetify.breakpoint.smAndDown, 'pa-5': $vuetify.breakpoint.mdAndUp}"
        >
          <div class="subheading text-header-color">
            <span v-html="selectedProfile.voornaam"></span>
            <span v-if="selectedProfile.achternaam" v-html="selectedProfile.achternaam"></span>
            <span v-if="selectedProfile.functie" v-html="' - ' + selectedProfile.functie"></span>
          </div>
          <div class="mt-2" v-html="selectedProfile.beschrijving"></div>
        </div>
      </div>
      <div v-else>Laden...</div>
    </div>
    <div class="pt-5">
    <v-img
      :aspect-ratio="20/9"
      :src="require('../../../assets/team.jpg')"
    ></v-img>
    </div>
  </div>
</template>
<script>
export default{
  data: function () {
    return {
      profiel: '',
      team: []
    }
  },
  mounted () {
    axios
      .get('/api/team')
      .then(response => (this.team = response.data))
  },
  computed: {
    selectedProfile: {
      get: function () {
        if (!this.profiel) {
          return this.team[0]
        }
        return this.profiel
      },
      set: function (newValue) {
        this.profiel = newValue
      }
    },
    headerImage () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return require('../../../assets/behandelkamer_small.jpg')
        default : return require('../../../assets/behandelkamer.jpg')
      }
    }
  },
  methods: {
    switchTab (selected) {
      this.profiel = selected
    }
  }
}
</script>
<style>

/* Gray Scale */
.hovergrey img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.hovergrey img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.pointer {
  cursor: pointer;
}

</style>
