<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      temporary
    >
      <v-list>
        <v-list-tile
          v-for="(link, i) in links"
          :key="i"
          :to="link.to"
          @click="onClick($event, link)"
        >
          <v-list-tile-title v-text="link.text" />
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      app
      color="white"
      class="py-2"
    >
      <v-toolbar-side-icon
        class="hidden-md-and-up"
        @click="toggleDrawer"
      >
        <v-icon>menu</v-icon>
      </v-toolbar-side-icon>
      <v-container
        mx-auto
        py-0
        my-2
      >
        <v-layout align-center>
          <div class="px-4">
            <router-link class="text-header-color" :to="{ name: 'overzicht' }">
              <v-img
                    :src="require('../../assets/logo.jpg')"
                    height="auto"
                    width="200"
                  >
                </v-img>
            </router-link>
          </div>
          <v-btn
            v-for="(link, i) in links"
            :key="i"
            :to="link.to"
            class="ml-0 hidden-sm-and-down"
            flat
          >
            {{ link.text }}
          </v-btn>
          <v-spacer />
          <h3 class="hidden-sm-and-down">
          <v-icon color="#073a66" style="vertical-align: middle">local_phone</v-icon>
        {{ telefoonnummer }}</h3>
        </v-layout>
      </v-container>
    </v-toolbar>
  </div>
</template>

<script>

export default {
  name: 'Toolbar',
  props: ['telefoonnummer'],
  data () {
    return {
      drawer: false,
      links: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Praktijk',
          to: '/praktijk'
        },
        {
          text: 'Behandelingen',
          to: '/behandelingen'
        },
        {
          text: 'Vergoeding',
          to: '/vergoeding'
        },
        {
          text: 'Contact',
          to: '/contact'
        }
      ]
    }
  },
  methods: {
    toggleDrawer () {
      this.drawer = !this.drawer
    },
    onClick (e, item) {
      e.stopPropagation()
      this.drawer = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.logo-dark-blue {
  fill: #10366b;
}
.logo-light-blue {
  fill: #269fd3;
}
</style>
