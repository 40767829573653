<template>
  <div>
    <div 
      :class="{'pa-3': $vuetify.breakpoint.smAndDown, 'pa-5': $vuetify.breakpoint.mdAndUp}"
    >
      <h1 class="headline text-sm-left text-header-color mb-4">
        Ontspannen, kleinschalig én persoonlijk
      </h1>
      <div
        :class="{'py-2': $vuetify.breakpoint.smAndDown, 'py-4': $vuetify.breakpoint.mdAndUp}" 
        >
        Van aanmeten tot zorg na het plaatsen van een prothese; bij onze praktijk kunt u rekenen op het hele traject van A tot Z. Elk gebit en elke prothese is anders en zo helpen wij ook; afgestemd op uw wensen en passend bij uw voorkeuren. En dat zorgt voor betere eindresultaten én tevreden cliënten.
      </div>
      <div class="pb-4">
        Onze persoonlijke werkwijze uit zich al tijdens de intake en het aanmeten, bij het vervaardigen van de prothese zelf en vanzelfsprekend ook bij het passen en uiteindelijk opleveren. Bovendien is onze praktijk kleinschalig van opzet, wat altijd zorgt voor een ontspannen sfeer.
      </div>
      <div class="pb-4">
        Maar we gaan nog een stap verder. Want niet alleen kunt u bij ons rekenen op kwaliteit, expertise en specialisme met een persoonlijke benadering, wij staan ook voor uitgebreide service. Bij ons kunt u altijd terecht voor vrijblijvend advies en controle en rekenen op optimale bereikbaarheid – ook in de avonduren en in het weekend. Wij geven namelijk om u en uw lach en doen er alles aan om u snel en vakkundig te helpen!
      </div>
      <h3 class="title text-header-color my-4">Hendrik Plasmans – Uw tandprotheticus</h3>
      
        <v-layout row wrap>
          <v-flex xs12 md9>
            <div class="pb-4">
              Tandprotheticus Hendrik Plasmans is oprichter en eigenaar van Tandprothetische Praktijk Plasmans te Best. Inmiddels al bijna 20 jaar werkzaam in de tandtechniek, waarvan ruim 6 jaar al als zelfstandig behandelaar. En dat merkt u. Hendrik is namelijk gespecialiseerd in het aanmeten, plaatsen en vervaardigen van uitneembare gebitsprotheses.
            </div>
            <div class="pb-4">
              Doordat Hendrik zich puur gefocust heeft op het ontwikkelen en vervaardigen van deze protheses, heeft hij inmiddels zoveel expertise opgebouwd dat hij elke keer weer waarde weet toe te voegen aan elk werkstuk dat wordt opgeleverd. En dat zorgt voor blije gezichten.
            </div>
          </v-flex>
          <v-flex md3 xs12 class="pa-2">
        <v-img
          :src="require('../../../assets/tandprotheticus.jpg')"
        ></v-img>
          </v-flex>
        </v-layout>
      <div class="pb-4">
        Door zijn positieve uitstraling en benadering voelen cliënten zich bij Hendrik al snel op hun gemak. Hij heeft een luisterend oor en vindt het belangrijk dat mensen zich prettig voelen in zijn praktijk. Maar nog belangrijker; geholpen worden op een manier die bij hen past, waardoor ze weer kunnen lachen!
      </div>
    </div>
    <v-carousel>
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        :aspect-ratio="20/9"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>

export default{
  data: function () {
    return {
      items: [
        {
          src: require('../../../assets/praktijk_1.jpg')
        },
        {
          src: require('../../../assets/praktijk_2.jpg')
        },
        {
          src: require('../../../assets/praktijk_3.jpg')
        },
        {
          src: require('../../../assets/praktijk_4.jpg')
        }
      ]
    }
  }
}

</script>