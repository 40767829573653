<template>
  <div>
    <v-container fluid pa-0 ma-0>
      <v-layout justify-center row wrap pa-4 class="white">
        <v-flex xs12 md6 pa-4>
          <h1 class="display-1 text-header-color py-5" v-html="artikel.titel"></h1>
          <div class="pb-5" v-html="artikel.inhoud">
          </div>
        </v-flex>
        <v-flex xs12 md4 pa-5 mt-5>
          <v-flex text-sm-right class="ma-4">
            <v-avatar
              size="100"
            >
              <img
                :src="require('../../assets/hendrik.jpg')"
                alt="avatar"
              />
            </v-avatar>
            <div class="body-1 text-uppercase text-header-color pt-4">Hendrik Plasmans</div>
            <div>Tandprotheticus</div>
            <div class="text-header-color">{{ algemeen.telefoonnummer }}</div>
            <a href='mailto:info@tpp-plasmans.nl' style="text-decoration: none" class="text-header-color">{{ algemeen.email }}</a>
          </v-flex>
          <v-flex text-sm-right class="ma-4">
            <h4 class="body-1 pb-2 text-uppercase text-sm-right">Tags</h4>
            <v-chip v-for="label in artikel.tags" outline color="blue" :key="label">{{ label }}</v-chip>
          </v-flex>
          <v-flex class="ma-4">
            <h4 class="body-1 text-uppercase text-sm-right">Alle Artikelen</h4>
          </v-flex>
          <ul style="list-style-type: none">
            <li v-for="item in informatie.artikelen" :key="item.titel">
              <v-icon color="#02646f" style="vertical-align: bottom">keyboard_arrow_right</v-icon>
              <router-link
                style="text-decoration: none"
                class="text-header-color"
                v-html="item.titel"
                :to="{ name: 'artikel', params: { titel: item.titel }}"
                ></router-link>
            </li>
          </ul>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default{
  data: function () {
    return {
      artikel: '',
      informatie: require('../../assets/artikelen.json'),
      algemeen: require('../../assets/algemeen.json')
    }
  },
  methods: {
    setArticle (titel) {
      for (var key in this.informatie.artikelen) {
        if (this.informatie.artikelen[key].titel === titel) {
          this.artikel = this.informatie.artikelen[key]
        }
      }
    },
    linkOtherArticle (titel) {
      return '/#/artikel/' + titel
    }
  },
  created () {
    this.setArticle(this.$route.params.titel)
  },
  watch: {
    '$route.params.titel': function (newTitel, oldTitel) {
      this.setArticle(newTitel)
    }
  }
}
</script>
