<template>
  <div id="app">
    <v-app
      :class="{'align-left': $vuetify.breakpoint.smAndDown, 'align-justify': $vuetify.breakpoint.mdAndUp}"
    >
      <toolbar :telefoonnummer="data.telefoonnummer"></toolbar>
      <router-view :tarieven="data.tarieven" :info="data"/>
      <bottom-footer :info="data"></bottom-footer>
    </v-app>
  </div>
</template>

<script>

import Toolbar from './components/Toolbar'
import BottomFooter from './components/Footer'

export default {
  name: 'App',
  components: {
    Toolbar,
    BottomFooter
  },
  data () {
    return {
      info: []
      }
  },
  props: [
    'data'
  ]
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50; 
  background-color: #e2e8ed;
  margin-top: 60px;
  line-height: 2;

}

.align-justify {
  text-align: justify;
}

.align-left {
  text-align: left;
}

.text-header-color {
  color: #269fd3;
}

.hover-sepia {
  -webkit-filter: sepia(40%);
  filter: sepia(40%);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.hover-sepia:hover {
  -webkit-filter: sepia(0);
  filter: sepia(0);
}

.router-link-exact-active * {
  color: #2196f3;
}

.hover-menu-blue:hover * {
  color: #2196f3;
}

.footer-blue {
  color: #2196f3;
}

.z-index-1 {
  z-index: 1;
}

.minus-top-margin {
  margin-top: -100px;
}

 .frontblue {
    border-color: #269fd3;
    background-color: #073a66;
 }

.box{
    float:left;
    height:10px;
    width:10px;
    margin-top: 10px;
    margin-right: 10px;
    background-color: #269fd3;
}

</style>
