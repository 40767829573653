<template>
  <div>
    <v-flex xs12>
      <v-img
        :src="headerImage"
      >
        <v-layout
          fill-height
          align-center
          class="pt-5"
        >
          <v-flex
            row
            wrap
            xs12
            md6
            text-xs-center
          >
            <h1 class="hidden-xs-only display-3 font-weight-light">
              {{ informatie.kop.titel }}
            </h1>
            <div class="hidden-xs-only subheading text-uppercase pl-2 mb-4">
              {{ informatie.kop.ondertitel }}
            </div>
            <div>
              <v-btn color="info" :to="{ name: 'contact' }">{{ informatie.kop.afspraak }}</v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-img>
    </v-flex>
    <v-flex xs12>
      <div class="white pa-5 text-xs-center">
        <div>
          <v-alert
            :value="true"
            color="teal darken-3"
            icon="info"
            outline
            v-if="info.melding"
          >
          <h4 v-html="info.melding"></h4>
          </v-alert>
        </div>
        <div class="pt-2">
            <v-dialog
                v-model="dialog"
                scrollable 
                width="800"
            >
              <template v-slot:activator="{ on }">
                
              </template>
              <corona :dialog="dialog" v-on:close="dialog = false"></corona>
            </v-dialog>
          </v-alert>
        </div>
      </div>
      <v-layout 
        align-space-around 
        justify-space-between 
        fill-height 
        class="white py-3"
        >
        <div class="xs6">
          <v-card 
            flat 
            :class="{'px-3': $vuetify.breakpoint.smAndDown, 'px-5': $vuetify.breakpoint.mdAndUp}"
            >
            <h3 class="headline align-space-between text-header-color">
              {{ informatie.introductie.titel }}
            </h3>
            <div class="py-4">{{ informatie.introductie.tekst }}</div>
          </v-card>
          <v-card 
            flat 
            :class="{'px-3': $vuetify.breakpoint.smAndDown, 'px-5': $vuetify.breakpoint.mdAndUp}"
            >
            <h3 
              class="headline align-space-between text-header-color"
            >
            {{ informatie.klachten.titel }}
          </h3>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 md6 class="pa-0 ma-0">
                  <ul class="pa-0 ma-0" style="list-style-type: none">
                    <li 
                      v-for="(item, index) in informatie.klachten.items1" 
                      :key="index" 
                      :class="{'px-2 pt-3': $vuetify.breakpoint.smAndDown, 'px-5 pt-3': $vuetify.breakpoint.mdAndUp}"
                    >
                      <v-icon color="#073a66" style="vertical-align: middle">keyboard_arrow_right</v-icon>
                      {{ item.item }}
                    </li>
                  </ul>
                </v-flex>
                <v-flex xs12 md6 class="pa-0 my-0 ml-0 mb-3">
                  <ul class="pa-0 ma-0" style="list-style-type: none">
                    <li 
                      v-for="(item, index) in informatie.klachten.items2" 
                      :key="index" 
                      :class="{'px-2 pt-3': $vuetify.breakpoint.smAndDown, 'px-5 pt-3': $vuetify.breakpoint.mdAndUp}"
                    >
                      <v-icon color="#073a66" style="vertical-align: middle">keyboard_arrow_right</v-icon>
                      {{ item.item }}
                    </li>
                  </ul>
                </v-flex>
              </v-layout>
            </v-container>
            <span>{{ informatie.klachten.ondertitel }}</span>
          </v-card>
        </div>
        <div class="xs6 hidden-sm-and-down">
          <v-img
            :src="require('../../assets/side_image.png')"
            height="auto"
            width="400"
          />
        </div>
      </v-layout>
    </v-flex>
    <v-flex>
      <v-container grid-list-xl>
            <v-alert
              :value="true"
              color="teal darken-3"
              icon="info"
              outline
              v-if="informatie.alert"
            >
            <h3>{{ informatie.alert }}</h3>
            </v-alert>
        <v-layout align-space-around justify-space-center row wrap fill-height>
          <v-flex xs12 sm6 lg4>
            <v-card color="#e2e8ed" style="height:100%; border-bottom: solid 5px #073a66; border-left: solid 5px #073a66" flat>
              <v-card-title primary-title>
                <div class="pa-2">
                  <h3 class="headline">{{ informatie.service.titel }}</h3>
                  <div class="pt-4">{{ informatie.service.ondertitel }}
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
            <v-flex xs12 sm6 lg4 v-for="service in informatie.service.blokken" :key="service.titel">
              <v-card style="height:100%">
              <v-img
                :src="require('../../assets/' + service.img + '.jpg')"
                aspect-ratio="2"
              ></v-img>
                <v-card-title primary-title>
                  <div 
                    :class="{'px-2 pb-2': $vuetify.breakpoint.smAndDown, 'px-4 pb-2': $vuetify.breakpoint.mdAndUp}"
                  >
                    <h3 class="title text-header-color">{{ service.titel }}</h3>
                    <div class="pt-4" v-html="service.ondertitel">
                    </div>
                  </div>
                </v-card-title>
              </v-card>
            </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <v-flex>
      <v-layout align-space-around justify-space-between fill-height class="white py-5">
        <v-card 
          flat 
          :class="{'px-3': $vuetify.breakpoint.smAndDown, 'px-5 pt-4': $vuetify.breakpoint.mdAndUp}"
        >
          <h3 class="headline align-space-between text-header-color">Vergoedingen</h3>
          <div class="py-4">De meeste zorgverzekeraars vergoeden de kosten van de tandprotheticus. Een volledig kunstgebit krijgt u vergoed uit uw basisverzekering. Meer weten? Lees <router-link to="/vergoeding">hier</router-link> meer over vergoedingen.</div>
        </v-card>
      </v-layout>
    </v-flex>
    <v-flex>
      <v-container grid-list-xl>
        <v-layout align-space-around justify-space-center row wrap fill-height>
          <v-flex xs12 md6 v-for="artikel in artikelen.artikelen" :key="artikel.titel">
            <v-hover>
              <v-card
                slot-scope="{ hover }"
                :class="`elevation-${hover ? 12 : 2}`"
              >
                <v-img
                  :src="require('../../assets/' + artikel.foto)"
                  aspect-ratio="2.75"
                ></v-img>
                <v-card-title primary-title>
                  <div class="text-truncate">
                    <h3 class="title text-header-color" v-html="artikel.titel"></h3>
                    <div class="pt-4">{{ getPostBody(artikel.inhoud ) }}
                    </div>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn flat color="blue" :to="{ name: 'artikel', params: { titel: artikel.titel }}">Lees verder</v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </div>
</template>

<script>

import Corona from './Corona'

export default{
  data: function () {
    return {
      informatie: require('../../assets/voorpagina.json'),
      artikelen: require('../../assets/artikelen.json'),
      dialog: false
    }
  },
  components: {
    Corona
  },
  props: ['info'],
  computed: {
    headerImage () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return require('../../assets/voorpagina_hendrik_small.jpg')
        default : return require('../../assets/voorpagina_hendrik.png')
      }
    }
  },
  methods: {
    getPostBody (artikel) {
      let body = this.stripTags(artikel)
      return body.length > 80 ? body.substring(0, 80) + '...' : body
    },
    stripTags (text) {
      return text.replace(/(<([^>]+)>)/ig, '')
    }
  }
}
</script>

<style scoped>
</style>
