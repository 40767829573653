<template>
  <div>
    <v-img
        :src="headerImage"
      >
        <v-layout
          fill-height
          align-end
          class="pt-5"
        >
          <v-flex
            xs12
          >
          <div class="frontblue pa-1"></div>
          </v-flex>
        </v-layout>
    </v-img>
    <v-container fluid pa-0 ma-0>
      <v-layout justify-center row wrap pa-4>
        <v-flex xs12 md3 my-2 mb-5>
          <div>
            <h2 class="my-2">Praktijk</h2>
            <div class="hover-menu-blue">
              <v-icon style="vertical-align: middle">chevron_right</v-icon>
              <router-link style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'werkwijze' }">
                <span class="hover-menu-blue" style="vertical-align: middle">Werkwijze</span>
              </router-link>
            </div>
            <div class="mb-5 pb-4 hover-menu-blue">
              <v-icon style="vertical-align: middle">chevron_right</v-icon>
              <router-link style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'team' }">
                <span class="hover-menu-blue" style="vertical-align: middle">Ons team</span>
              </router-link>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 md6 minus-top-margin z-index-1 white>
          <div class="frontblue pa-1"/>
          <router-view/>
          <div class="frontblue pa-1"/>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

export default{
  data: function () {
    return {
      informatie: require('../../assets/praktijk.json'),
      profiel: ''
    }
  },
  computed: {

    headerImage () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return require('../../assets/behandelkamer_small.jpg')
        default : return require('../../assets/behandelkamer.jpg')
      }
    }
  }
}
</script>

<style>

</style>
