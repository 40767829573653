<template>
  <div>
    <v-img
        :src="headerImage"
      >
        <v-layout
          fill-height
          align-end
          class="pt-5"
        >
          <v-flex
            xs12
          >
          <div class="frontblue pa-1">
          </div>
          </v-flex>
        </v-layout>
    </v-img>
    <v-container fluid pa-0 ma-0>
      <v-layout justify-center row wrap pa-4>
        <v-flex xs12 md3 my-2 mb-3>
          <div class="mb-5 pb-5">
            <h2 class="my-2">Vergoeding</h2>
            <div class="hover-menu-blue">
              <v-icon style="vertical-align: middle">chevron_right</v-icon>
              <router-link style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'zorgverkeraar' }">
                <span class="hover-menu-blue" style="vertical-align: middle">Uw zorgverzekeraar</span>
              </router-link>
            </div>
            <div class="pb-4 hover-menu-blue">
              <v-icon style="vertical-align: middle">chevron_right</v-icon>
              <router-link style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'tarieven' }">
                <span class="hover-menu-blue" style="vertical-align: middle">Tarieven</span>
              </router-link>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 md6 minus-top-margin z-index-1 white>
          <div class="frontblue pa-1"/>
            <router-view :tarieven="tarieven"/>
          <div class="frontblue pa-1"/>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

export default{
  computed: {
    headerImage () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return require('../../assets/rekening_small.jpg')
        default : return require('../../assets/rekening.jpg')
      }
    }
  },
  props: ['tarieven']
}
</script>
