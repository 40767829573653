<template>
  <div>
    <v-img
        :src="headerImage"
      >
        <v-layout
          fill-height
          align-end
          class="pt-5"
        >
          <v-flex
            xs12
          >
          <div class="frontblue pa-1">
          </div>
          </v-flex>
        </v-layout>
    </v-img>
    <v-container fluid class="white" pa-0 ma-0>
      <v-layout 
        justify-center 
        row 
        wrap 
        :class="{'pa-2': $vuetify.breakpoint.smAndDown, 'pa-4': $vuetify.breakpoint.mdAndUp}"
      >
        <v-flex 
          xs12 
          md5 
          :class="{'pa-2': $vuetify.breakpoint.smAndDown, 'pa-4': $vuetify.breakpoint.mdAndUp}"
        >
          <h3 class="title text-sm-left text-header-color my-4">Stuur een bericht</h3>
          <form 
            id="email-form"
            @submit.prevent="submit"
          >
            <input 
              type="hidden" 
              name="_token" 
              :value="csrf">
            <v-text-field
              id="naam"
              v-model="naam"
              :counter="99"
              label="Naam"
              required
            ></v-text-field>
            <v-text-field
              id="nummer"
              v-model="nummer"
              :counter="20"
              label="Telefoonnummer"
            ></v-text-field>
            <v-text-field
              id="email"
              v-model="email"
              :counter="99"
              label="E-mail"
            ></v-text-field>
            <v-textarea
              id="bericht"
              v-model="bericht"
              :counter="9999"
              label="Uw bericht"
              required
            ></v-textarea>
            <v-flex>
              <v-btn type="submit" color="info">Verstuur</v-btn>
            </v-flex>
            <div v-if="success" class="alert alert-success mt-3">
              Bericht verstuurd!
            </div>
          </form>
        </v-flex>
        <v-flex 
          xs12 
          md5 
          :class="{'pa-2': $vuetify.breakpoint.smAndDown, 'pa-4': $vuetify.breakpoint.mdAndUp}"
        >
          <v-flex>
            <h3 class="title text-sm-left text-header-color my-4">Meer weten of een afspraak maken</h3>
            <v-flex text-sm-left>
              <div class="pb-4">
                Wilt u weer onbezorgd kunnen lachen? Heeft u een vraag? Of wilt u meteen een afspraak maken? Neem dan contact met ons op, wij staan voor u klaar!
              </div>
            </v-flex>
          </v-flex>
          <v-flex>
            <h3 class="title text-sm-left text-header-color my-4">Adres</h3>
            <v-flex text-sm-left>
              <div>{{ info.adres }}</div>
              <div>{{ info.postcode }}</div>
              <div>{{ info.stad }}</div>
            </v-flex>
          </v-flex>
          <v-flex>
            <h3 class="title text-sm-left text-header-color my-4">Openingstijden</h3>
              <v-layout justify-start row wrap py-2 text-sm-left>
                <v-flex>
                    <div>Maandag</div>
                    <div>Dinsdag</div>
                    <div>Woensdag</div>
                    <div>Donderdag</div>
                    <div>Vrijdag</div>
                    <div>Zaterdag</div>
                    <div>Zondag</div>
                </v-flex>
                <v-flex>
                  <div>{{ info.opening_maandag }}</div>
                  <div>{{ info.opening_dinsdag }}</div>
                  <div>{{ info.opening_woensdag }}</div>
                  <div>{{ info.opening_donderdag }}</div>
                  <div>{{ info.opening_vrijdag }}</div>
                  <div>{{ info.opening_zaterdag }}</div>
                  <div>{{ info.opening_zondag }}</div>
                </v-flex>
              </v-layout>
          </v-flex>
          <v-flex v-if="info.spreekuur">
            <h3 class="title text-sm-left text-header-color my-4">Telefonisch Spreekuur</h3>
            <v-flex text-sm-left>
              <div class="pb-2">
                {{ info.spreekuur }}
              </div>
            </v-flex>
          </v-flex>
          <v-flex>
            <h3 class="title text-sm-left text-header-color my-4">Heeft u een klacht?</h3>
            <div>
              <v-flex>
              Tandprothetische Praktijk Plasmans is aangesloten bij de Organisatie Nederlandse Tandprothetici. Wij doen onze uiterste beste om onze cliënten de beste kwaliteit en service te bieden. Heeft u desondanks toch een klacht over onze behandelingen, de nazorg of de kwaliteit die wij leveren? Neem dan contact op met Organisatie Nederlandse Tandprothetici (ONT), Postbus 12, 3740 AA Baarn, per email: info@ont.nl
              </v-flex>
            </div>
          </v-flex>
        </v-flex>
        <v-flex xs12 ma-0 pa-0>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.4611272101333!2d5.4009009157274335!3d51.50475557963467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6dd1fb4009591%3A0x936d86c91f04bca3!2sTandprothetische%20Praktijk%20Plasmans!5e0!3m2!1sen!2snl!4v1567610474759!5m2!1sen!2snl" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="">
          </iframe>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default{
  data: function () {
    return {
      naam: '',
      nummer: '',
      email: '',
      bericht: '',
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      errors: {},
      success: false,
      loaded: true
    }
  },
  props: ['info'],
  computed: {
    headerImage () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return require('../../assets/praktijk_small.jpg')
        default : return require('../../assets/praktijk.jpg')
      }
    }
  },
  methods: {
    submit() {
      if (this.loaded) {
        this.loaded = false
        this.errors = {};
        axios.post('api/form', {
          naam: this.naam,
          nummer: this.nummer,
          email: this.email,
          bericht: this.bericht,
          csrf: this.csrf
        }).then(response => {
          this.naam = ''
          this.nummer = ''
          this.email = ''
          this.bericht = ''
          this.loaded = true
          this.success = true
          this.response = 'Bericht verstuurd'
        }).catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors || {};
          }
        });
      }
    }
  }
}
</script>
