import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import App from './App'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
  iconfont: 'mdi'
})

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'overzicht',
      component: Overzicht
    },
    {
      path: '/praktijk',
      name: 'praktijk',
      component: Praktijk,
      children: [
        {
          path: '',
          name: 'werkwijze',
          component: Werkwijze
        },
        {
          path: 'team',
          name: 'team',
          component: Team
        }
      ]
    },
    {
      path: '/behandelingen',
      name: 'behandelingen',
      component: Behandelingen,
      children: [
        {
          path: '',
          name: 'kwaliteit',
          component: Kwaliteit
        },
        {
          path: 'klikgebit',
          name: 'klikgebit',
          component: Klikgebit
        },
        {
          path: 'volledige_prothese',
          name: 'volledige_prothese',
          component: VolledigeProthese
        },
        {
          path: 'rebasing',
          name: 'rebasing',
          component: Rebasing
        },
        {
          path: 'eerste_prothesee',
          name: 'eerste_prothesee',
          component: EersteProthese
        },
        {
          path: 'reparatie',
          name: 'reparatie',
          component: ReparatieKunstgebit
        },
        {
          path: 'bleekmallen',
          name: 'bleekmallen',
          component: Bleekmallen
        }
      ]
    },
    {
      path: '/vergoeding',
      name: 'vergoeding',
      component: Vergoeding,
      children: [
        {
          path: '',
          name: 'zorgverkeraar',
          component: Zorgverzekeraar
        },
        {
          path: 'tarieven',
          name: 'tarieven',
          component: Tarieven
        }
      ]
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    {
      path: '/artikel/:titel',
      name: 'artikel',
      component: Artikel
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});



Vue.config.productionTip = false

import Overzicht from './components/Overzicht'
import Praktijk from './components/Praktijk'
import Behandelingen from './components/Behandelingen'
import Vergoeding from './components/Vergoeding'
import Contact from './components/Contact'
import Artikel from './components/Artikel'
import Klikgebit from './components/Behandelingen/Klikgebit'
import EersteProthese from './components/Behandelingen/EersteProthese'
import Rebasing from './components/Behandelingen/Rebasing'
import ReparatieKunstgebit from './components/Behandelingen/ReparatieKunstgebit'
import VolledigeProthese from './components/Behandelingen/VolledigeProthese'
import Kwaliteit from './components/Behandelingen/Kwaliteit'
import Bleekmallen from './components/Behandelingen/Bleekmallen'
import Zorgverzekeraar from './components/Vergoeding/Zorgverzekeraar'
import Tarieven from './components/Vergoeding/Tarieven'
import Team from './components/Praktijk/Team'
import Werkwijze from './components/Praktijk/Werkwijze'
import Privacy from './components/Privacy'



/*
const Behandelingen  = () => import('./components/Behandelingen')
const Vergoeding  = () => import('./components/Vergoeding')
const Contact  = () => import('./components/Contact')
const Artikel  = () => import('./components/Artikel')
const Klikgebit  = () => import('./components/Behandelingen/Klikgebit')
const EersteProthese  = () => import('./components/Behandelingen/EersteProthese')
const Rebasing  = () => import('./components/Behandelingen/Rebasing')
const ReparatieKunstgebit  = () => import('./components/Behandelingen/ReparatieKunstgebit')
const VolledigeProthese  = () => import('./components/Behandelingen/VolledigeProthese')
const Kwaliteit  = () => import('./components/Behandelingen/Kwaliteit')
const Bleekmallen  = () => import('./components/Behandelingen/Bleekmallen')
const Zorgverzekeraar  = () => import('./components/Vergoeding/Zorgverzekeraar')
const Tarieven  = () => import('./components/Vergoeding/Tarieven')
const Team  = () => import('./components/Praktijk/Team')
const Werkwijze  = () => import('./components/Praktijk/Werkwijze')
const Privacy  = () => import('./components/Privacy')
*/
const app = new Vue({
    el: '#app',
    components: { App },
    router,
});
