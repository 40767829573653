<template>
  <div>
    <div
      :class="{'pa-3': $vuetify.breakpoint.smAndDown, 'pa-5': $vuetify.breakpoint.mdAndUp}"
    >
      <h1 class="headline text-sm-left text-header-color">
        Wij hebben contracten met alle zorgverzekeraars
      </h1>
      <div class="pb-4 mb-4">
      </div>
      <div class="pb-4">
        In Nederland kennen wij een verplichte basisverzekering met een wettelijk eigen risico (€ 385,- in 2020). Daarnaast kan men een vrijwillige aanvullende (tandarts)verzekering afsluiten voor zorg die niet in de basisverzekering is opgenomen. Een nota van een tandprotheticus bestaat uit twee delen: een honorarium deel en een tandtechniek deel. De tarieven stelt de NZa (Nederlandse Zorgautoriteit) jaarlijks vast in opdracht van de minister.  
      </div>
      <div class="pb-4">
        Voor vergoeding van prothetische zorg door uw zorgverzekeraar gelden de volgende algemene regels;
      </div>
      <div class="pb-4">
        <h3 class="subtitle text-header-color my-4">
          Volledige prothese boven, onder of boven & onder
        </h3>
        De vergoeding vanuit de basisverzekering bedraagt 75% van de totale kosten.
        <br>
        De eigen bijdrage, het deel dat u niet uit de basisverzekering vergoed krijgt, bedraagt 25% van de totale kosten. De eigen bijdrage kan gedeeltelijk tot geheel vergoed worden vanuit de aanvullende verzekering. Dat verschilt per aanvullende verzekering. Let op! Zie opmerking over eigen risico.
      </div>
      <div class="pb-4">
        <h3 class="subtitle text-header-color my-4">
          Reparaties en rebasing van een volledige prothese
        </h3>
          Reparaties en rebasingen kennen een vergoeding vanuit de basisverzekering van 90%.
        <br>
          De eigen bijdrage, het deel dat u niet uit de basisverzekering vergoed krijgt, bedraagt 10%.
        <br>
          Let op! Zie opmerking over eigen risico.
      </div>
      <div class="pb-4">
        <h3 class="subtitle text-header-color my-4">
          Volledige prothese op implantaten
        </h3>
        <ul>
          <li>Een volledige onderprothese op implantaten kent een eigen bijdrage van 10%.</li>
          <li>Een volledige bovenprothese op implantaten kent een eigen bijdrage van 8%.</li>
          <li>Een tegelijkertijd vervaardigde normale bovenprothese, in combinatie met een implantaat gedragen onderprothese, kent een samengestelde eigen bijdrage van 17% en 10%.</li>
          <li>Een tegelijkertijd vervaardigde normale onderprothese, in combinatie met een implantaat gedragen bovenprothese, kent een samengestelde eigen bijdrage van 17% en 8%.</li>
        </ul>
        <br>
        De eigen bijdrage, het deel dat u niet uit de basisverzekering vergoed krijgt, kan gedeeltelijk tot geheel vergoed worden vanuit de aanvullende verzekering.
        <br>
        Dit verschilt per aanvullende verzekering. Let op! Zie opmerking over eigen risico. 
      </div>
      <div class="pb-4">
        <h3 class="subtitle text-header-color my-4">
          Kosten implantologie
        </h3>
        Het vooronderzoek, de röntgenfoto en het implanteren van implantaten ten behoeve van een prothese op implantaten wordt voor 100 % vergoed vanuit de basisverzekering. Let op! Zie opmerking over eigen risico.
        <br>
        Wanneer het implanteren én het plaatsen van de prothese op implantanten in twee verschillende kalenderjaren plaatsvindt, kan het voorkomen dat tweemaal het eigen risico wordt belast. Bespreek dit met uw tandprotheticus.
        <br>
        Wanneer het plaatsen van de implantaten niet doorgaat, zijn de kosten voor foto’s en consult voor rekening van patiënt wanneer er geen machtiging is. De basisverzekering vergoedt deze kosten dan dus niet.
      </div>
      <div class="pb-4">
        <h3 class="subtitle text-header-color my-4">
          Kosten immediaat procedure (tijdelijke) noodprothese)
        </h3>
          Het tweemaal belasten van het eigen risico kan ook voorkomen wanneer het plaatsen van de noodprothese (immediaat) en het plaatsen van de normale prothese in twee verschillende kalenderjaren plaatsvindt. Uiteindelijk volgt na een (tijdelijke) noodprothese vaak een definitieve gebitsprothese. 
      </div>
      <div class="pb-4">
        <h3 class="subtitle text-header-color my-4">
          Partiële prothese
        </h3>
        De kosten van een partiële prothese (plaatje) of frame prothese vallen niet onder de basisverzekering. Deze kosten komen in principe voor eigen rekening. Het kan wel zo zijn dat uw aanvullende verzekering de kosten geheel of gedeeltelijk vergoed. Dit verschilt per aanvullende verzekering.
        <br>
        Ook de kosten van reparaties en rebasingen van een partiële prothese of frame kunnen geheel of gedeeltelijk vergoed worden uit de aanvullende verzekering. Dit verschilt per aanvullende verzekering. 
      </div>
      <div class="pb-4">
        <h3 class="subtitle text-header-color my-4">
          Opmerking over eigen risico
        </h3>
        Het wettelijk eigen risico is dat deel van de jaarlijkse zorgkosten die een persoon, die zorgkosten maakt, zelf moet betalen. Het is bedoeld als drempel om te voorkomen dat mensen overbodig van zorg gebruik maken.
        <br>
        In 2020 bedraagt het wettelijk vastgesteld eigen risico € 385,-. Voor alle boven genoemde vergoedingen voor prothetische zorg (vanuit de basisverzekering) geldt dit wettelijk eigen risico. U heeft daarnaast de keuze uw eigen vrijwillig risico te verhogen, waardoor uw basispremie lager zal zijn. 
      </div>
      <div class="pb-4">
        <h3 class="subtitle text-header-color my-4">
          Opmerking over machtigingen
        </h3>
        Voor sommige behandelingen vereist uw zorgverzekeraar een zogenoemde machtigingsaanvraag of toestemmingsaanvraag. Uw verzekeraar moet dan eerst toestemming geven voor een bepaalde behandeling. De tandprotheticus vraagt de machtiging voor u aan bij de zorgverzekeraar. Het kan voorkomen dat de tandprotheticus een overeenkomst met uw zorgverzekeraar heeft afgesloten. In deze overeenkomsten wordt vaak afgesproken dat een machtiging niet nodig is. Uw ONT-tandprotheticus kan u hier meer over vertellen.
      </div>
      <div class="pb-4">
        Lees 
        <router-link :to="{ name: 'artikel', params: { titel: 'Wat kost een <b>kunstgebit</b>?' }}">hier</router-link>
        hier verder voor twee rekenvoorbeelden.
      </div>
      <div class="pb-4">
        Op <a href="kunstgebit.nl" target="_blank">www.kunstgebit.nl</a> kunt u meer informatie vinden over de verschillende kosten en vergoedingen
      </div>
    </div>
          <v-img
            :aspect-ratio="20/9"
            :src="require('../../../assets/tarieven.jpg')"
          ></v-img>
    </div>
</template>

<script>

export default{
  props: ['tarieven']
}
</script>