<template>
  <div>
    <v-img
        :src="headerImage"
      >
        <v-layout
          fill-height
          align-end
          class="pt-5"
        >
          <v-flex
            xs12
          >
          <div class="frontblue pa-1">
          </div>
          </v-flex>
        </v-layout>
    </v-img>
    <v-container fluid pa-0 ma-0>
      <v-layout justify-center row wrap pa-4>
        <v-flex xs12 md3 my-2 mb-5>
          <div>
            <h2 class="my-2">Behandelingen</h2>
            <div class="hover-menu-blue">
              <v-icon style="vertical-align: middle">chevron_right</v-icon>
              <router-link style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'kwaliteit' }">
                <span class="hover-menu-blue" style="vertical-align: middle">Kwaliteit</span>
              </router-link>
            </div>
            <div class="hover-menu-blue">
              <v-icon style="vertical-align: middle">keyboard_arrow_right</v-icon>
              <router-link class-active="menu-blue" style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'klikgebit' }">
                <span class="hover-menu-blue" style="vertical-align: middle">Prothese op implantaten (klikgebit)</span>
              </router-link>
            </div>
            <div class="hover-menu-blue">
              <v-icon class="" style="vertical-align: middle">keyboard_arrow_right</v-icon>
              <router-link class-active="menu-blue" style="text-decoration: none"  class="grey--text text--darken-2" :to="{ name: 'volledige_prothese' }">
                <span class="hover-menu-blue" style="vertical-align: middle">Volledige prothese</span>
              </router-link>
            </div>
            <div class="hover-menu-blue">
              <v-icon class="" style="vertical-align: middle">keyboard_arrow_right</v-icon>
              <router-link class-active="menu-blue" style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'rebasing' }">
                <span class="hover-menu-blue" style="vertical-align: middle">Rebasing</span>
              </router-link>
            </div>
            <div class="hover-menu-blue">
              <v-icon class="" style="vertical-align: middle">keyboard_arrow_right</v-icon>
              <router-link class-active="menu-blue" style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'eerste_prothesee' }">
                <span class="hover-menu-blue" style="vertical-align: middle">Eerste prothese</span>
              </router-link>
            </div>
            <div class="hover-menu-blue">
              <v-icon class="" style="vertical-align: middle">keyboard_arrow_right</v-icon>
              <router-link class-active="menu-blue" style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'reparatie' }">
                <span style="vertical-align: middle">Reparatie kunstgebit</span>
              </router-link>
            </div>
            <div class="hover-menu-blue pb-4 mb-5">
              <v-icon class="" style="vertical-align: middle">keyboard_arrow_right</v-icon>
              <router-link class-active="menu-blue" style="text-decoration: none" class="grey--text text--darken-2" :to="{ name: 'bleekmallen' }">
                <span style="vertical-align: middle">Bleekmallen en gebitsbeschermer</span>
              </router-link>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 md6 minus-top-margin z-index-1 white>
          <div class="frontblue pa-1"/>
          <router-view/>
          <div class="frontblue pa-1"/>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
/*
import Kwaliteit from './components/Behandelingen/Kwaliteit'
import Klikgebit from './components/Behandelingen/Klikgebit'
import VolledigeProthese from './components/Behandelingen/VolledigeProthese'
import Rebasing from './components/Behandelingen/Rebasing'
import EersteProthese from './components/Behandelingen/EersteProthese'
import ReparatieKunstgebit from './components/Behandelingen/ReparatieKunstgebit'
import Bleekmallen from './components/Behandelingen/Bleekmallen'
*/

export default{
  computed: {
    headerImage () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return require('../../assets/tanden_small.jpg')
        default : return require('../../assets/tanden.jpg')
      }
    }
  }
}
</script>
